import { useCallback, useEffect, useState } from 'react';

const doesQueryMatch = (query: string): boolean => {
    return window.matchMedia(query).matches;
};

export const useMediaQueryProps = <Type>(
    propsMap: { [key: string]: Type },
    defaultProps: Type
): Type => {
    const [matchingProps, setMatchingProps] = useState<Type>(defaultProps);

    const checkMatchingQueries = useCallback(() => {
        const matching = Object.entries(propsMap).find(([query]) => {
            return doesQueryMatch(query);
        });
        if (!matching) {
            setMatchingProps(defaultProps);
            return;
        }
        setMatchingProps(matching[1]);
    }, [propsMap, defaultProps, setMatchingProps]);

    useEffect(() => {
        checkMatchingQueries();
    }, [checkMatchingQueries]);

    useEffect(() => {
        const matchers = Object.keys(propsMap).map((query) => window.matchMedia(query));
        matchers.forEach((matcher) => {
            if (matcher.addEventListener) {
                matcher.addEventListener('change', checkMatchingQueries);
            } else {
                matcher.addListener(checkMatchingQueries);
            }
        });
        return () => {
            matchers.forEach((matcher) => {
                if (matcher.removeEventListener) {
                    matcher.removeEventListener('change', checkMatchingQueries);
                } else {
                    matcher.removeListener(checkMatchingQueries);
                }
            });
        };
    }, [propsMap, checkMatchingQueries]);

    return matchingProps;
};

export default useMediaQueryProps;
