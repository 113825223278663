import { FunctionComponent } from 'react';
import Container from '../../../../components/Container';
import styles from '../../../../pages/404.module.scss';
import Image from 'next/image';
import Link from 'next/link';
// @ts-ignore This fails type checking in CI because it's in the public folder
import NotFoundOwl from '../../../../public/404-owl.png';

export const EducatorNotFound: FunctionComponent = () => {
    return (
        <Container>
            <div className={styles.innerContainer}>
                <div>
                    <h1>Oops! We can&apos;t find that educator.</h1>
                    <h2>Why am I seeing this?</h2>
                    <ul>
                        <li>
                            We cannot access a page on the URL you entered. The educator may not
                            exist, or they might have hidden their profile from our marketplace.
                        </li>
                        <li>Check the URL for typos.</li>
                        <li>
                            You can check out some of our other great offerings through our{' '}
                            <Link href={`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/courses`}>
                                marketplace search
                            </Link>
                            .
                        </li>
                        <li>
                            If you think you are seeing this page in error you can{' '}
                            <a href="mailto:support@cademy.io">reach out to our support team</a> to
                            report the broken link.
                        </li>
                    </ul>
                </div>
                <div className={styles.image_container}>
                    <Image src={NotFoundOwl} alt="oops" width={250} height={250} />
                </div>
            </div>
        </Container>
    );
};
