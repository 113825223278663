'use client';

import {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useId,
    useMemo,
    useRef,
    useState,
} from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import BlueprintIcon from '../BlueprintIcon';

type BaseCarouselProps = {
    name: string;
    children: Array<ReactNode>;
    numberOfVisible?: number;
    smooth?: boolean;
    classNames?: {
        container?: string;
        previousButton?: string;
        nextButton?: string;
        scroller?: string;
        item?: string;
    };
};

export type UncontrolledCarouselProps = BaseCarouselProps & {
    controlled?: false;
    index?: never;
    setIndex?: never;
};

export type ControlledCarouselProps = BaseCarouselProps & {
    controlled: true;
    index: number;
    setIndex: (newIndex: number) => void;
};

export type CarouselProps = UncontrolledCarouselProps | ControlledCarouselProps;

export const Carousel: FunctionComponent<CarouselProps> = ({
    name,
    children,
    classNames,
    controlled,
    index,
    setIndex,
    numberOfVisible = 1,
    smooth = true,
}) => {
    const id = useId();
    const [uncontrolledIndex, setUncontrolledIndex] = useState<number>(0);
    const lastIndexSetByScroll = useRef<number | null>(null);
    const [currentIndex, setCurrentIndex] = useMemo(() => {
        if (controlled === true) {
            return [index, setIndex];
        } else {
            return [uncontrolledIndex, setUncontrolledIndex];
        }
    }, [controlled, index, setIndex, uncontrolledIndex, setUncontrolledIndex]);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (currentIndex === lastIndexSetByScroll.current) {
            return;
        }
        lastIndexSetByScroll.current = null;
        if (!container.current) {
            return;
        }
        const scroller = container.current.querySelector<HTMLElement>(`[id="${id}-scroller"]`);
        if (!scroller) {
            return;
        }
        const currentItem = scroller.children[currentIndex] as HTMLElement;
        if (!currentItem) {
            return;
        }
        const delta = Math.abs(scroller.offsetLeft - currentItem.offsetLeft);
        scroller.scrollTo({ behavior: smooth ? 'smooth' : 'auto', left: delta, top: 0 });
    }, [id, controlled, smooth, container, currentIndex]);

    const setIndexAndScroll = useCallback(
        (newIndex: number) => {
            setCurrentIndex(newIndex);
            if (!container.current) {
                return;
            }
            const scroller = container.current.querySelector<HTMLElement>(`[id="${id}-scroller"]`);
            if (!scroller) {
                return;
            }
            const currentItem = scroller.children[newIndex] as HTMLElement;
            if (!currentItem) {
                return;
            }
            const delta = Math.abs(scroller.offsetLeft - currentItem.offsetLeft);
            scroller.scrollTo({ behavior: smooth ? 'smooth' : 'auto', left: delta, top: 0 });
        },
        [id, setCurrentIndex, smooth, container]
    );

    const chunkedChildren = useMemo(() => {
        return Array.from(new Array(Math.ceil(children.length / numberOfVisible)).keys()).map(
            (index) => {
                const offset = index * numberOfVisible;
                const chunk = children.slice(offset, offset + numberOfVisible);
                const missingItemDivs = Array.from(
                    new Array(numberOfVisible - chunk.length).keys()
                ).map((index) => <div key={index}></div>);
                return [...chunk, ...missingItemDivs];
            }
        );
    }, [children, numberOfVisible]);

    useEffect(() => {
        if (!container.current) {
            return;
        }
        const scroller = container.current.querySelector<HTMLElement>(`[id="${id}-scroller"]`);
        if (!scroller) {
            return;
        }
        const observer = new IntersectionObserver(
            (observations) => {
                observations.forEach((observation) => {
                    if (observation.isIntersecting) {
                        const index = Array.from(scroller.children).indexOf(observation.target);
                        lastIndexSetByScroll.current = index;
                        setCurrentIndex(index);
                    }
                });
            },
            {
                root: scroller,
                threshold: 0.6,
            }
        );
        Array.from(scroller.children).forEach((child) => observer.observe(child));
        return () => {
            Array.from(scroller.children).forEach((child) => observer.unobserve(child));
            observer.disconnect();
        };
    }, [id, container, chunkedChildren.length, setCurrentIndex]);

    const onNextClick = useCallback(() => {
        if (currentIndex === chunkedChildren.length) {
            return;
        }
        setIndexAndScroll(currentIndex + 1);
    }, [currentIndex, setIndexAndScroll, chunkedChildren]);

    const onPreviousClick = useCallback(() => {
        if (currentIndex === 0) {
            return;
        }
        setIndexAndScroll(currentIndex - 1);
    }, [currentIndex, setIndexAndScroll]);

    useEffect(() => {
        setIndexAndScroll(0);
    }, [children, setIndexAndScroll]);

    return (
        <div
            aria-label={name}
            id={id}
            role="group"
            aria-roledescription="carousel"
            tabIndex={-1}
            ref={container}
            className={classnames(styles.container, classNames?.container)}
        >
            {currentIndex !== 0 ? (
                <button
                    title="Previous item"
                    type="button"
                    disabled={currentIndex === 0}
                    className={classnames(styles.previousButton, classNames?.previousButton)}
                    aria-controls={`${id}-scroller`}
                    aria-label="Previous item"
                    onClick={onPreviousClick}
                >
                    <BlueprintIcon icon="ArrowLeft" />
                </button>
            ) : null}
            {currentIndex !== chunkedChildren.length - 1 ? (
                <button
                    title="Next item"
                    type="button"
                    disabled={currentIndex === chunkedChildren.length - 1}
                    className={classnames(styles.nextButton, classNames?.nextButton)}
                    aria-controls={`${id}-scroller`}
                    aria-label="Next item"
                    onClick={onNextClick}
                >
                    <BlueprintIcon icon="ArrowRight" />
                </button>
            ) : null}
            <div
                id={`${id}-scroller`}
                aria-label={`${name} items scroller`}
                aria-live="polite"
                className={classnames(styles.scroller, classNames?.scroller)}
            >
                {chunkedChildren.map((chunk, index) => {
                    const isCurrent = index === currentIndex;
                    return (
                        <div
                            className={classnames(styles.item, classNames?.item)}
                            key={index}
                            tabIndex={isCurrent ? 0 : -1}
                            role="group"
                            aria-roledescription="slide"
                            aria-label={`${index + 1} of ${chunkedChildren.length}`}
                        >
                            {chunk}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Carousel;
