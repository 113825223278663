import { useParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import {
    EducatorPageProps,
    getEducatorDetails,
} from '../../pages_components/[educator_param]/[course_param]/getEducatorDetails';
import { useAppContext } from '../../contexts/AppContext/useAppContext';

type FetchingEducatorStatus = 'initialised' | 'fetching' | 'failed' | 'successful';

export const useEducatorParam = (): {
    educator: EducatorPageProps | null;
    status: FetchingEducatorStatus;
} => {
    const params = useParams();
    const context = useAppContext();
    const [educator, setEducator] = useState<EducatorPageProps | null>(null);
    const [status, setStatus] = useState<FetchingEducatorStatus>('initialised');
    const educatorParam: string | null = useMemo(() => {
        if (!params) {
            return null;
        }

        if (context === 'minisite') {
            return Array.isArray(params.subdomain) ? params.subdomain[0] : params.subdomain;
        }

        return Array.isArray(params.educator_param)
            ? params.educator_param[0]
            : params.educator_param;
    }, [context, params]);

    useEffect(() => {
        const fetchEducator = async () => {
            setStatus('fetching');
            if (!educatorParam) {
                setStatus('failed');
                return null;
            }
            try {
                const educator = await getEducatorDetails(educatorParam);
                if (!educator) {
                    setStatus('failed');
                    return null;
                }
                setStatus('successful');
                setEducator(educator);
            } catch (error) {
                console.error(error);
                setStatus('failed');
                return null;
            }
        };

        if (educatorParam) {
            fetchEducator();
        }
    }, [educatorParam]);

    return { educator, status };
};
