import { Category, Educator, MarketplaceTag, Membership } from '@shared/types';

export type EducatorPageProps = {
    educator: Educator;
    memberships: Array<Membership>;
    vouchers_app_installed: boolean;
    categories: Array<Category>;
    marketplace_tags: Array<MarketplaceTag>;
};

export const getEducatorDetails = async (educatorParam: string): Promise<EducatorPageProps> => {
    const response = await fetch(
        `${process.env.NEXT_PUBLIC_CADEMY_API_URL}/get-educator-data/${educatorParam}?is_marketplace=true`,
        {
            credentials: 'include',
        }
    );
    const result = await response.json();
    if (response.status === 404) {
        const error = new Error('404 - Not found');
        throw error;
    }
    if (response.ok === false) {
        const error = await response.text();
        throw new Error(error);
    }
    return {
        educator: result.educator,
        memberships: result.memberships,
        vouchers_app_installed: result.vouchers_app_installed,
        categories: result.categories,
        marketplace_tags: result.marketplace_tags,
    };
};
