'use client';

import { SearchIndexCourseFilters } from '@shared/types';
import Link from 'next/link';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import useMediaQueryProps from '../../hooks/useMediaQueryProps';
import Carousel, { CarouselProps } from '../Carousel';
import styles from './styles.module.scss';
import { CourseSearch, SearchIndexCourseResult } from '../../services/search/course';
import CourseCard from '../CourseCard';
import { useCourseVisibility } from '../../hooks/useCourseVisibility';
import { useSavedCoursesState } from '../../hooks/useSavedCoursesState';

export type CourseCarouselProps = Omit<CarouselProps, 'children' | 'numberOfVisible'> & {
    filters: Omit<SearchIndexCourseFilters, 'context'>;
    endCardText: string;
    endCardLink: string;
    mediaQueries?: {
        [key: string]: { numberOfVisible: number };
    };
    hiddenIfEmpty?: boolean;
    showVATLabel?: boolean;
    heading?: string;
};

const defaultMediaQueries = {
    '(min-width: 560px) and (max-width: 889px)': {
        numberOfVisible: 2,
    },
    '(min-width: 890px) and (max-width: 1019px)': {
        numberOfVisible: 3,
    },
    '(min-width: 1020px)': {
        numberOfVisible: 4,
    },
};

const defaultMediaQuery = {
    numberOfVisible: 1,
};

export const CourseCarousel: FunctionComponent<CourseCarouselProps> = ({
    filters,
    endCardText,
    endCardLink,
    mediaQueries = defaultMediaQueries,
    hiddenIfEmpty = false,
    showVATLabel = false,
    heading,
    ...carouselProps
}) => {
    const { numberOfVisible } = useMediaQueryProps(mediaQueries, defaultMediaQuery);
    const [courses, setCourses] = useState<SearchIndexCourseResult['results']>([]);
    const courseVisibility = useCourseVisibility();
    const courseIds = useMemo(() => courses.map((course) => course._id), [courses]);
    const { data: savedCoursesStatus } = useSavedCoursesState({ courseIds });

    const filtersWithContext = useMemo(() => {
        return { ...filters, context: courseVisibility };
    }, [filters, courseVisibility]);

    useEffect(() => {
        CourseSearch('', filtersWithContext, 1, 11, 'relevance').then((result) => {
            setCourses(result.results);
        });
    }, [filtersWithContext]);

    if (!courses.length) {
        return null;
    }

    return (
        <>
            {heading ? <h2>{heading}</h2> : null}
            <Carousel {...(carouselProps as CarouselProps)} numberOfVisible={numberOfVisible}>
                {courses
                    .map((course) => (
                        <div className={styles.courseCardWrapper} key={course._id}>
                            <CourseCard
                                key={course._id}
                                course={course}
                                saved={savedCoursesStatus[course._id]}
                                showVATLabel={showVATLabel}
                            />
                        </div>
                    ))
                    .concat([
                        <div key="end-card" className={styles.endCard}>
                            <p>{endCardText}</p>
                            <Link href={endCardLink}>View More ›</Link>
                        </div>,
                    ])}
            </Carousel>
        </>
    );
};
